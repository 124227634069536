import React from "react";
import { Link } from "react-router-dom";
import logo_light from '../../assets/images/blurb-light.png';
import {FaRegEnvelope,FaDribbble,FaLinkedin, FaFacebookF, FaInstagram, FaTwitter,FaRegFile,FaBehance, PiShoppingCart, MdKeyboardArrowRight} from '../../assets/icons/icons'

export default function JobFooter(){
    return(
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
            <div className="container relative text-center">
                <div className="grid grid-cols-1">
                    <div className="py-[30px] px-0">
                        <div className="grid md:grid-cols-3 items-center">
                            <div className="md:text-start text-center">
                                   {/* <ul className="list-none mt-5 space-x-1 space-y-1">
                                 
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="#" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter className='text-sm'/></Link></li>
                                
                                </ul> */}
                            </div>

                            <div className="text-center">
                                <p className="mb-0">© 2024 Edukrypt Corporation Pvt. Ltd.</p>
                            </div>

                            {/* <ul className="list-none space-x-1 footer-list md:text-end text-center mt-6 md:mt-0">
                                <li className="inline"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Terms & Condition</Link></li>
                                <li className="inline mt-[10px]"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Privacy Policy</Link></li>
                                <li className="inline mt-[10px]"><Link to="#" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Contact</Link></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid grid-cols-1">
                        <div className="text-center">
                            <p className="mb-0">©  Techwind. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="https://shreethemes.in/" target="_blank"className="text-reset">Shreethemes</Link>.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
    )
}